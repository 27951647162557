

.select-holder {
  @apply relative inline-block;

  .popup {
    @apply w-auto left-0;
    top: 100%;
    max-height: 200px;
    overflow-y: auto;

    .menu-item {
      @apply w-full hover:bg-gray-100 text-left;
      &:focus {
        outline: none;
      }
    }
  }
}
.select {
  @apply appearance-none inline-block max-w-full bg-white rounded shadow border px-3 py-2 max-w-full focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-left;
  //min-width: 300px;
  min-height: 42px;
  &:focus {
    outline: none;
  }
  &:disabled {
    @apply opacity-60;
  }

  &::after {
    @apply absolute;
    content: "\e91b";

    font-family: 'ti-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    top: 50%;
    right: 6px;
    transform: translateY(-50%);
  }
  &.open::after {
    content: "\e91a";
  }
}
.select-search-holder {
  @apply relative overflow-hidden;
  height: 290px;

  input {
    min-width: auto;
    width: 100%;
  }
}
.select-search-items {
  @apply absolute overflow-y-auto w-full bottom-0;
  top: 50px;
}

.cds--dropdown {
  .cds--list-box__menu {
    inline-size: min-content;
    min-inline-size: 100%;
    .cds--list-box__menu-item {
      width: 100%;
    }
  }

  
}

.cds--dropdown__wrapper.right {
  .cds--list-box__menu {
    inset-inline-start: initial;
  }
}