@use "@carbon/react/scss/components/data-table";
@use "@carbon/react/scss/components/data-table/sort";
@use "@carbon/react/scss/components/data-table/expandable";
@use "@carbon/styles/scss/themes";
@use "@carbon/styles/scss/theme";

.additional-providers-table {
    .cds--data-table {
        tr {
            block-size: auto;
        }
        thead tr {
            min-block-size: 1.5rem;
        }
        height: 100%;
        max-block-size: 100%;
        span {
            inline-size: 100%;
            min-inline-size: 0;
        }
    }

    .cds--data-table-container {
        height: 100%;
    }

    .cds--data-table-content {
        height: 100%;
    }
    
    .cds--data-table_inner-container {
        height: calc(100% - 60px - 32px);
    }

    .cds--data-table-header {
        @apply pb-4;
    }

    .select-col {
        @apply pr-0;
        max-inline-size: 36px;
    }

    .cds--checkbox-label-text {
        display: none;
    }

    .checked-row {
        @apply bg-gray-200;
    }

    .cds--data-table--sticky-header thead {
        overflow: visible;
    }
}
