@use '@carbon/styles/scss/theme';
@use '@carbon/styles/scss/themes';
@use '@carbon/react/scss/zone';

:root[data-carbon-theme="g10"] {
  @include theme.theme(themes.$g10);
}

:root[data-carbon-theme="g100"] {
  @include theme.theme(themes.$g100);
}

:root[data-carbon-theme="g90"] {
  @include theme.theme(themes.$g90);
}
